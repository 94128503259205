import { Component, ViewContainerRef, input } from '@angular/core';

import { MarkdownComponent } from '../markdown/markdown.component';
import { Studycontent } from 'src/app/data/types';
import { NgIconComponent } from '@ng-icons/core';
import { ActionSheetComponent } from 'ngx-concern';

@Component({
  selector: 'app-study-sheet',
  templateUrl: './study-sheet.component.html',
  styleUrls: ['./study-sheet.component.scss'],
  standalone: true,
  imports: [MarkdownComponent, NgIconComponent],
})
export class StudySheetComponent {
  public inhalt = input.required<Studycontent>();
  public bottomRef = input.required<ActionSheetComponent>();

  constructor(private ref: ViewContainerRef) {}

  get tabLink() {
    return `${this.inhalt().sluggedTitle}`;
  }

  get refElement() {
    return this.ref.element.nativeElement as HTMLElement;
  }

  get markdownScrollTop() {
    return this.refElement.querySelector('markdown.content')?.scrollTop || 0;
  }

  public close(event: MouseEvent, title: HTMLElement) {
    if (event.target === title) this.bottomRef().close();
  }
}
