import { Component, ViewContainerRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { StudySheetComponent } from '../study-sheet/study-sheet.component';
import { StudyModalComponent } from '../study-modal/study-modal.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { Studycontent } from 'src/app/data/types';
import { ActionSheetComponent, DialogComponent } from 'ngx-concern';

@Component({
  selector: 'app-studium',
  templateUrl: './studium.component.html',
  styleUrls: ['./studium.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    AsyncPipe,
    StudySheetComponent,
    StudyModalComponent,
    ActionSheetComponent,
    DialogComponent,
  ],
})
export class StudiumComponent {
  #content = inject(ContentService);

  studieninhalte$ = toObservable(this.#content.studyContents);

  selectedContent$ = new BehaviorSubject<Studycontent | null>(null);

  selectContent(inhalt: Studycontent) {
    this.selectedContent$.next(inhalt);
  }
}
