import { Injectable, computed, signal } from '@angular/core';
import { Data, isData } from 'src/app/data/types';
import dataJson from '../data/data.json';

if (!isData(dataJson)) {
  throw new Error('data.json is invalid');
}

const data = dataJson as Data;

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  readonly data = signal(data);
  readonly events = computed(() =>
    this.data().events.sort((a, b) => {
      if (a.day === b.day) {
        return a.time.localeCompare(b.time) * -1;
      }
      return a.day.localeCompare(b.day);
    })
  );
  readonly members = computed(() =>
    this.data().members.sort((a, b) => {
      if (a.priority === b.priority) {
        return a.name.localeCompare(b.name);
      }
      return a.priority - b.priority;
    })
  );
  readonly formerMembers = computed(() => this.members().filter((m) => m.formerMember));
  readonly currentMembers = computed(() => this.members().filter((m) => !m.formerMember));
  readonly studyContents = computed(() =>
    this.data().studycontent.sort((a, b) => {
      if (a.priority === b.priority) {
        return a.title.localeCompare(b.title);
      }
      return a.priority - b.priority;
    })
  );
}
