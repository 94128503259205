export type Data = {
  events: Event[];
  members: Member[];
  studycontent: Studycontent[];
};
export const isData = (data: any): data is Data => {
  return 'events' in data && 'members' in data && 'studycontent' in data;
};

export type Event = {
  title: string;
  subtitle?: string;
  url?: string;
  location: string;
  day: string;
  time: string;
  enabled: boolean;
  content: string;
  image?: string;
};
export const isEvent = (event: any): event is Event => {
  return (
    'title' in event &&
    'location' in event &&
    'day' in event &&
    'time' in event &&
    'enabled' in event &&
    'content' in event
  );
};

export type Member = {
  name: string;
  formerMember: boolean;
  enabled: boolean;
  image?: string;
  priority: number;
  role?: string;
  content: string;
};
export const isMember = (member: any): member is Member => {
  return (
    'name' in member &&
    'formerMember' in member &&
    'enabled' in member &&
    'image' in member &&
    'priority' in member &&
    'content' in member
  );
};

export type Studycontent = {
  title: string;
  enabled: boolean;
  priority: number;
  content: string;
  sluggedTitle: string;
};
export const isStudycontent = (studycontent: any): studycontent is Studycontent => {
  return (
    'title' in studycontent &&
    'enabled' in studycontent &&
    'priority' in studycontent &&
    'content' in studycontent
  );
};
