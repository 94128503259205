<div id="background-container">
  <a class="impressum" routerLink="/impressum" title="Impressum">Impressum</a>
  <div id="center">
    <h1 class="desktop nowrap">Fachschaft<br />Medieninformatik /<br />Informationsverarbeitung</h1>
    <h1 class="mobile nowrap">
      Fachschaft<br />Medieninformatik /<br />Informations-<br />verarbeitung
    </h1>
    <p>
      Auf unserer Website findet ihr Informationen rund um die Fachschaft und die Studiengänge
      Medieninformatik und Informationsverarbeitung des Instituts für Digital Humanities der
      Universität zu Köln.
    </p>
  </div>
  <a
    class="nav-jump-arrow"
    href="#studium"
    title="Navigiere zu Studientipps"
    (click)="scrollToStudium()"
  >
    <ng-icon name="matArrowDownward"></ng-icon>
  </a>
</div>
