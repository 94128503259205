import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Marked, marked, MarkedOptions, Renderer } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { mangle } from 'marked-mangle';

marked.use(gfmHeadingId({}), mangle());

@Injectable({
  providedIn: 'root',
})
export class MarkdownService {
  private renderer = new Renderer();

  private options: MarkedOptions = {
    renderer: this.renderer,
  };

  constructor(private sanitizer: DomSanitizer) {
    this.renderer.link = ({ href, title, text }) => {
      const safeHref = this.sanitizer.sanitize(SecurityContext.URL, href);
      if (!title) title = text;
      return `<a href="${safeHref}" target="_blank" title="${title}" referrerpolicy="no-referrer" rel="noreferrer" alt="${title}">${text}</a>`;
    };
  }

  public render(markdown: string, options?: MarkedOptions) {
    return marked(markdown, { ...this.options, ...options, async: true });
  }
}
