import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject,
  input,
} from '@angular/core';
import { getWindow } from '../helpers/window';

@Directive({
  selector: '[appIsVisible]',
  standalone: true,
})
export class IsVisibleDirective implements OnInit, OnDestroy {
  @Output() isVisible = new EventEmitter<boolean>();

  unobserveIfVisible = input<boolean>(false);

  #observer: IntersectionObserver | undefined;
  #ref = inject(ElementRef);

  constructor() {
    const window = getWindow();
    if (!window || !('IntersectionObserver' in window)) return;
    this.#observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          this.isVisible.emit(true);
          if (this.unobserveIfVisible()) this.#observer?.unobserve(entry.target);
        } else {
          this.isVisible.emit(false);
          entry.target.classList.remove('is-visible');
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    );
  }

  ngOnInit(): void {
    this.#observer?.observe(this.#ref.nativeElement);
  }

  ngOnDestroy(): void {
    this.#observer?.disconnect();
  }
}
