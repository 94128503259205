<h2 (click)="close($event, title)" #title>
  {{ inhalt().title }}
  <a
    href="/studium/{{ tabLink }}"
    title="In neuem Tab öffnen"
    class="button-open-in-new"
    target="_blank"
    ><ng-icon name="matOpenInNew"></ng-icon
  ></a>
</h2>
<markdown [content]="inhalt().content" class="content" #markdown></markdown>
