<app-toolbar color="primary">
  <a routerLink="/" title="Zurück">
    <app-fs-logo />
    <span>Zurück zur Homepage</span>
  </a>

  <div id="scrolling-indicator" #scrollIndicator></div>
</app-toolbar>

<div class="container" #scrollContainer>
  <ng-container *ngIf="inhalt$ | async as inhalt">
    <h1>{{ inhalt.title }}</h1>

    <markdown [content]="inhalt.content" class="content"></markdown>
  </ng-container>
</div>
