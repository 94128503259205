import { Pipe, PipeTransform } from '@angular/core';
import { Member, Event } from './data/types';

@Pipe({
  name: 'correctImageUrl',
  standalone: true,
})
export class CorrectImageUrlPipe implements PipeTransform {
  transform(value: Member | Event): string {
    return value.image
      ? value.image.replace('/uploads/', '/assets/uploads/')
      : '/assets/placeholder.png';
  }
}
