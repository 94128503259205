<div class="introduction span-wide">
  <h1>Wer sind wir überhaupt?</h1>
  <h2>
    Unsere very important <span class="underlined">Mit</span>g<span class="underlined">lieder</span>
  </h2>
</div>

<div class="mitglieder">
  @for (mitglied of mitglieder(); track mitglied.name) {
    <div class="mitglied">
      <img
        defaultImage="../../../assets/placeholder.png"
        [lazyLoad]="mitglied | correctImageUrl"
        [alt]="'Bild von Mitglied ' + mitglied.name"
        width="64"
        height="64"
      />

      <div class="content" appIsVisible [unobserveIfVisible]="true">
        <span class="name">{{ mitglied.name }}</span>
        <span class="role" *ngFor="let role of mitglied.role | splitRoles">{{ role }}</span>

        <markdown
          class="mitglied-markdown"
          *ngIf="mitglied.content"
          [content]="mitglied.content"
        ></markdown>
      </div>
    </div>
  }
</div>

<div class="introduction span-wide">
  <h1>Hall of Fame</h1>
  <h2>
    Unsere very important <span class="underlined">Ehemali</span>g<span class="underlined">en</span>
  </h2>
</div>

<div class="mitglieder">
  @for (mitglied of ehemaligeMitglieder(); track mitglied.name) {
    <div class="mitglied">
      <img
        defaultImage="../../../assets/placeholder.png"
        [lazyLoad]="mitglied | correctImageUrl"
        [alt]="'Bild von Mitglied ' + mitglied.name"
        width="64"
        height="64"
      />

      <div class="content" appIsVisible [unobserveIfVisible]="true">
        <span class="name">{{ mitglied.name }}</span>
        <span class="role" *ngFor="let role of mitglied.role | splitRoles">{{ role }}</span>

        <markdown
          class="mitglied-markdown"
          *ngIf="mitglied.content"
          [content]="mitglied.content"
        ></markdown>
      </div>
    </div>
  }
</div>
