<h1>404</h1>

<h2>Hier solltest du nicht sein</h2>

<p>Um zurück zur eigentlichen Seite zu kommen, folge dem Logo</p>

<a routerLink="/" href="/" title="Startseite">
  <img
    src="../../assets/fslogo_vector.svg"
    alt="Logo-Link zurück zur Startseite"
    height="256"
    width="256"
  />
</a>
