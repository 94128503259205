import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Query,
  QueryList,
  ViewChildren,
  contentChildren,
  inject,
  viewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SplitRolesPipe } from '../../split-roles.pipe';
import { MarkdownComponent } from '../markdown/markdown.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { CorrectImageUrlPipe } from '../../correct-image-url.pipe';
import { IsVisibleDirective } from 'src/app/directives/is-visible.directive';

@Component({
  selector: 'app-mitglieder',
  templateUrl: './mitglieder.component.html',
  styleUrls: ['./mitglieder.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    LazyLoadImageModule,
    NgIf,
    MarkdownComponent,
    AsyncPipe,
    SplitRolesPipe,
    CorrectImageUrlPipe,
    IsVisibleDirective,
  ],
})
export class MitgliederComponent {
  #content = inject(ContentService);
  mitglieder = this.#content.currentMembers;
  ehemaligeMitglieder = this.#content.formerMembers;
}
