import { Component, Inject, input } from '@angular/core';
import { MarkdownComponent } from '../markdown/markdown.component';
import { Studycontent } from 'src/app/data/types';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-study-modal',
  templateUrl: './study-modal.component.html',
  styleUrls: ['./study-modal.component.scss'],
  standalone: true,
  imports: [MarkdownComponent, NgIconComponent],
})
export class StudyModalComponent {
  public inhalt = input.required<Studycontent>();

  get tabLink() {
    return `${this.inhalt().sluggedTitle}`;
  }
}
