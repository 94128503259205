import { Component, input } from '@angular/core';
import { ActionSheetComponent } from 'ngx-concern';

@Component({
  selector: 'app-kontakt-sheet',
  templateUrl: './kontakt-sheet.component.html',
  styleUrls: ['./kontakt-sheet.component.scss'],
  standalone: true,
})
export class KontaktSheetComponent {
  bottomRef = input.required<ActionSheetComponent>();
}
