<div class="introduction">
  <h1>Rund ums <span class="underlined">Studium</span></h1>
  <h2>
    Dein Survivalguide<br class="mobile" />
    für BSI &amp; BIT
  </h2>
</div>

<div class="list desktop">
  @for (inhalt of studieninhalte$ | async; track inhalt.sluggedTitle) {
    <div class="list-item" (click)="selectContent(inhalt); dialog.open()">{{ inhalt.title }}</div>
  }
</div>

<div class="list mobile">
  @for (inhalt of studieninhalte$ | async; track inhalt.sluggedTitle) {
    <div class="list-item" (click)="selectContent(inhalt); sheet.open()">{{ inhalt.title }}</div>
  }
</div>

<concern-action-sheet #sheet>
  @if (selectedContent$ | async; as content) {
    <app-study-sheet [inhalt]="content" [bottomRef]="sheet"></app-study-sheet>
  }
</concern-action-sheet>

<concern-dialog #dialog>
  @if (selectedContent$ | async; as content) {
    <app-study-modal [inhalt]="content"></app-study-modal>
  }
</concern-dialog>
