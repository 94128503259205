import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { routes } from './app-routing.module';
import { withConcernTheme, CONCERN_BACKDROP_STYLE } from 'ngx-concern';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, LazyLoadImageModule),
    { provide: LOCALE_ID, useValue: 'de' },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(
      routes,
      withInMemoryScrolling({ anchorScrolling: 'disabled' }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    withConcernTheme('default', {
      provide: CONCERN_BACKDROP_STYLE,
      useValue: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(4px)',
      },
    }),
  ],
};
