import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  inject,
  input,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { ReplaySubject, fromEvent } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { MarkdownComponent } from '../../components/markdown/markdown.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ContentService } from '../../services/content.service';
import { Studycontent } from 'src/app/data/types';
import { toObservable } from '@angular/core/rxjs-interop';
import { FsLogoComponent } from '../../icons/fs-logo/fs-logo.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';

@Component({
  selector: 'app-study-page',
  templateUrl: './study-page.component.html',
  styleUrls: ['./study-page.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf, MarkdownComponent, AsyncPipe, FsLogoComponent, ToolbarComponent],
})
export class StudyPageComponent implements AfterViewInit {
  @ViewChild('scrollIndicator')
  private scrollIndicator: ElementRef<HTMLDivElement> | undefined;

  @ViewChild('scrollContainer')
  private scrollContainer: ElementRef<HTMLDivElement> | undefined;

  #content = inject(ContentService);
  slug = input.required<string>();

  inhalt$ = toObservable(this.slug).pipe(
    map((slug) => this.#content.studyContents().find((s) => s.sluggedTitle === slug))
  );

  get scrollContainerEl() {
    return this.scrollContainer?.nativeElement;
  }

  private updateScrollIndicator() {
    if (!this.scrollIndicator || !this.scrollContainerEl) return;
    const { scrollTop, scrollHeight, clientHeight } = this.scrollContainerEl;
    const percent = Math.round(100 * (scrollTop / (scrollHeight - clientHeight)));
    const adjustedPercent = percent > 95 ? 100 : percent < 5 ? 0 : percent;
    this.scrollIndicator.nativeElement.style.width = adjustedPercent + '%';
  }

  ngAfterViewInit() {
    if (!this.scrollContainerEl) return;
    fromEvent(this.scrollContainerEl, 'scroll')
      .pipe(throttleTime(50))
      .subscribe(() => this.updateScrollIndicator());
  }
}
