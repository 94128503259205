<div class="introduction">
  <h1>Termine und <span class="underlined">Events</span></h1>
  <h2>
    die ihr <span class="underlined">nicht</span> <br class="mobile" />
    verpassen solltet
  </h2>
</div>

<div class="page-buttons desktop">
  <ng-container *ngIf="offset$ | async as offset; else noUpcomingEvents">
    <div
      class="page-button upcoming"
      *ngFor="let event of upcomingEvents$ | async; let index = index"
      [class.active]="selectedIndex === index"
      (click)="selectEvent(index)"
    ></div>
    <div class="spacer"></div>
    <div
      class="page-button past"
      *ngFor="let event of pastEvents$ | async; let index = index"
      [class.active]="selectedIndex === index + offset"
      (click)="selectEvent(index + offset)"
    ></div>
  </ng-container>
  <ng-template #noUpcomingEvents>
    <div
      class="page-button past"
      *ngFor="let event of pastEvents$ | async; let index = index"
      [class.active]="selectedIndex === index"
      (click)="selectEvent(index)"
    ></div>
  </ng-template>
</div>

<div class="container">
  <div class="tab-group">
    @for (event of events$ | async; track $index; let index = $index) {
      <div class="tab" [class.tab-active]="index === selectedIndex">
        <div class="event-image-container" [class.past-event]="isPastEvent$ | async">
          <img
            defaultImage="../../../assets/placeholder.png"
            [lazyLoad]="event | correctImageUrl"
            [alt]="'Bild zum Event ' + event.title"
            width="128"
            height="128"
            class="event-image"
          />
        </div>

        <div class="content">
          <div class="row desktop title-row">
            <ng-icon name="matTitle"></ng-icon>
            <h1>{{ event.title }}</h1>
          </div>

          <div class="row desktop" *ngIf="event.subtitle" [class.past-event]="isPastEvent$ | async">
            <ng-icon name="matSubtitles"></ng-icon>
            <h2>{{ event.subtitle }}</h2>
          </div>

          <div class="row mobile title-row">
            <ng-icon name="matTitle"></ng-icon>
            <h2>{{ event.title }}</h2>
          </div>

          <div class="row mobile" *ngIf="event.subtitle" [class.past-event]="isPastEvent$ | async">
            <ng-icon name="matSubtitles"></ng-icon>
            <h3>{{ event.subtitle }}</h3>
          </div>

          <div class="row" [class.past-event]="isPastEvent$ | async">
            <ng-icon name="matPlace"></ng-icon>
            <span class="event-info">{{ event.location }}</span>
          </div>

          <div class="row" [class.past-event]="isPastEvent$ | async">
            <ng-icon name="matLink"></ng-icon>
            <span class="event-info">
              <a
                [href]="event.url"
                target="_blank"
                title="Link zur Veranstaltung"
                referrerpolicy="no-referrer"
                rel="noreferrer"
                >Link zur Veranstaltung</a
              >
            </span>
          </div>

          <div class="row" [class.past-event]="isPastEvent$ | async">
            <ng-icon name="matEvent"></ng-icon>
            <span class="event-info">
              {{ event.day | date: 'EEEE - d. MMM yy' : '+1200' : 'de' }} &#64;
              {{ event.time | formatTimeString }} Uhr
            </span>
          </div>

          <button class="mobile select-event-button" (click)="eventSheet.open()">
            Anderes Event auswählen
          </button>
        </div>

        <div class="row description">
          <markdown class="selected-event-markdown" [content]="event.content"></markdown>
        </div>
      </div>
    }
  </div>
</div>

<concern-action-sheet #eventSheet>
  @if (events$ | async; as events) {
    <app-event-selection [bottomRef]="eventSheet" [events]="events"></app-event-selection>
  }
</concern-action-sheet>
