import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { FsLogoComponent } from '../../icons/fs-logo/fs-logo.component';
import { getWindow } from 'src/app/helpers/window';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, RouterLink, FsLogoComponent],
})
export class ImpressumComponent {
  @ViewChild('scrollIndicator')
  private scrollIndicator: ElementRef<HTMLDivElement> | undefined;

  constructor(@Inject(DOCUMENT) private document: Document) {
    fromEvent(this.document, 'scroll')
      .pipe(throttleTime(50))
      .subscribe(() => this.updateScrollIndicator());
  }

  private updateScrollIndicator() {
    if (!this.scrollIndicator) return;
    const window = getWindow();
    if (!window) return;
    const { scrollTop, scrollHeight } = this.document.documentElement;
    const percent = 100 * (scrollTop / (scrollHeight - window.innerHeight));
    this.scrollIndicator.nativeElement.style.width = percent + '%';
  }
}
