import { Component, ViewContainerRef, inject, input, output } from '@angular/core';
import { DOCUMENT, NgFor, DatePipe, AsyncPipe } from '@angular/common';

import { Event } from 'src/app/data/types';
import { ActionSheetComponent } from 'ngx-concern';

@Component({
  selector: 'app-event-selection',
  templateUrl: './event-selection.component.html',
  styleUrls: ['./event-selection.component.scss'],
  standalone: true,
  imports: [NgFor, DatePipe, AsyncPipe],
})
export class EventSelectionComponent {
  events = input.required<Event[]>();
  bottomRef = input.required<ActionSheetComponent>();
  selected = output<number>();
  ref = inject(ViewContainerRef);
  #document = inject(DOCUMENT);

  get refElement() {
    return this.ref.element.nativeElement as HTMLElement;
  }

  get actionListScrollTop() {
    return this.refElement.querySelector('div.list')?.scrollTop || 0;
  }

  close(index: number) {
    this.selected.emit(index);
    this.bottomRef().close();
  }
}
