import { Routes } from '@angular/router';

import { MainPageComponent } from './pages/main-page/main-page.component';
import { StudyPageComponent } from './pages/study-page/study-page.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { ErrorComponent } from './pages/error/error.component';

export const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
  },
  {
    path: 'studium/:slug',
    component: StudyPageComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];
