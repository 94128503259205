<app-toolbar color="primary">
  <a routerLink="/" title="Zurück" class="desktop">Zurück</a>

  <div id="links">
    <a routerLink="/" title="Startseite" class="mobile">
      <app-fs-logo />
    </a>
    <a href="/impressum#impressum" title="Impressum">Impressum</a>
    <a href="/impressum#datenschutz" title="Datenschutz">Datenschutz</a>
  </div>

  <div id="scrolling-indicator" #scrollIndicator></div>
</app-toolbar>

<div class="section" id="impressum">
  <h1>Impressum</h1>
  <h2 id="m46">Diensteanbieter</h2>
  <p>Kai Niebes</p>
  <p>An der Hasenhecke 9</p>
  <p>53902 Bad Münstereifel</p>
  <p>Deutschland</p>
  <ul class="m-elements"></ul>
  <h2 id="m56">Kontaktmöglichkeiten</h2>
  <p>
    <strong>E-Mail-Adresse</strong>:
    <a href="mailto:kai.niebes@outlook.com">kai.niebes&#64;outlook.com</a>
  </p>
  <p><strong>Telefon</strong>: +49 2257 959 447</p>
  <p>
    <strong>Weitere Kontaktmöglichkeiten</strong>: Mail, Facebook Messenger, Instagram Messenger und
    Slack (siehe Kontaktmöglichkeiten in der Sidebar)
  </p>
  <h2 id="m172">Social Media und andere Onlinepräsenzen</h2>
  <p>
    <strong
      >Dieses Impressum gilt auch für die folgenden Social-Media-Präsenzen und Onlineprofile</strong
    >:
  </p>
  <p>Verlinkte Social-Media-Profile auf der Mitglieder-Seite</p>
  <h2 id="m65">Haftungs- und Urheberrechtshinweise</h2>
  <p>
    <strong>Haftungsausschluss</strong>: Die Inhalte dieses Onlineangebotes wurden sorgfältig und
    nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten
    keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende
    Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder Widerrufsbelehrungen für
    Verbraucher) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern
    oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind
    freibleibend und unverbindlich.
  </p>
  <p>
    <strong>Links auf fremde Webseiten</strong>: Inhalte fremder Webseiten, auf die wir direkt oder
    indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und machen wir uns nicht zu
    Eigen. Für alle Inhalte und insbesondere für Schäden, die aus der Nutzung der in den verlinkten
    Webseiten aufrufbaren Informationen entstehen, haftet allein der Anbieter der verlinkten
    Webseiten.
  </p>
  <p>
    <strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser Website dargestellten Inhalte,
    wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte
    (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen
    unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteverwalter.
  </p>
  <p>
    <strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie innerhalb unseres Internetauftritts
    Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige
    Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.
  </p>
  <p></p>
  <p class="seal">
    <a
      href="https://datenschutz-generator.de/?l=de"
      title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
      target="_blank"
      referrerpolicy="no-referrer"
      rel="noreferrer"
      >Erstellt mit Datenschutz-Generator.de von Dr. jur. Thomas Schwenke</a
    >
  </p>
</div>

<div class="section" id="datenschutz">
  <h1>Datenschutzerklärung</h1>
  <h2 id="m14">Einleitung</h2>
  <p>
    Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
    personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken
    und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
    Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als
    auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
    Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet
    als "Onlineangebot“).
  </p>
  <ul class="m-elements"></ul>
  <p>Stand: 1. Oktober 2019</p>
  <h2>Inhaltsübersicht</h2>
  <ul class="index">
    <li><a class="index-link" href="#m14"> Einleitung</a></li>
    <li><a class="index-link" href="#m3"> Verantwortlicher</a></li>
    <li><a class="index-link" href="#mOverview"> Übersicht der Verarbeitungen</a></li>
    <li><a class="index-link" href="#m11"> Kontakt Datenschutzbeauftragter</a></li>
    <li><a class="index-link" href="#m13"> Maßgebliche Rechtsgrundlagen</a></li>
    <li><a class="index-link" href="#m27"> Sicherheitsmaßnahmen</a></li>
    <li>
      <a class="index-link" href="#m25">
        Übermittlung und Offenbarung von personenbezogenen Daten</a
      >
    </li>
    <li><a class="index-link" href="#m24"> Datenverarbeitung in Drittländern</a></li>
    <li>
      <a class="index-link" href="#m354">
        Wahrnehmung von Aufgaben nach Satzung oder Geschäftsordnung</a
      >
    </li>
    <li><a class="index-link" href="#m182"> Kontaktaufnahme</a></li>
    <li><a class="index-link" href="#m391"> Kommunikation via Messenger</a></li>
    <li>
      <a class="index-link" href="#m225"> Bereitstellung des Onlineangebotes und Webhosting</a>
    </li>
    <li><a class="index-link" href="#m29"> Cloud-Dienste</a></li>
    <li><a class="index-link" href="#m136"> Präsenzen in sozialen Netzwerken</a></li>
    <li>
      <a class="index-link" href="#m328"> Plugins und eingebettete Funktionen sowie Inhalte</a>
    </li>
    <li><a class="index-link" href="#m723"> Planung, Organisation und Hilfswerkzeuge</a></li>
    <li><a class="index-link" href="#m12"> Löschung von Daten</a></li>
    <li>
      <a class="index-link" href="#m15"> Änderung und Aktualisierung der Datenschutzerklärung</a>
    </li>
    <li><a class="index-link" href="#m42"> Begriffsdefinitionen</a></li>
  </ul>
  <h2 id="m3">Verantwortlicher</h2>
  <p>Kai Niebes<br />An der Hasenhecke 9<br />53902 Bad Münstereifel</p>
  <p>
    <strong>E-Mail-Adresse</strong>:
    <a href="mailto:kai.niebes@outlook.com">kai.niebes&#64;outlook.com</a>
  </p>
  <p><strong>Telefon</strong>: +49 2257 959 447</p>
  <p>
    <strong>Impressum</strong>:
    <a
      href="http://www.fs-medien.info/impressum"
      target="_blank"
      referrerpolicy="no-referrer"
      rel="noreferrer"
      >www.fs-medien.info/impressum</a
    >
  </p>
  <ul class="m-elements"></ul>
  <h2 id="m11">Kontakt Datenschutzbeauftragter</h2>
  <p><a href="mailto:kai.niebes@outlook.com">kai.niebes&#64;outlook.com</a></p>
  <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
  <p>
    Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
    Verarbeitung zusammen und verweist auf die betroffenen Personen.
  </p>
  <h3>Arten der verarbeiteten Daten</h3>
  <ul>
    <li><p>Bestandsdaten (z.B. Namen, Adressen).</p></li>
    <li><p>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p></li>
    <li><p>Kontaktdaten (z.B. E-Mail, Telefonnummern).</p></li>
    <li><p>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p></li>
    <li><p>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</p></li>
    <li><p>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</p></li>
    <li><p>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</p></li>
  </ul>
  <h3>Kategorien betroffener Personen</h3>
  <ul>
    <li><p>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</p></li>
    <li><p>Geschäfts- und Vertragspartner.</p></li>
    <li><p>Interessenten.</p></li>
    <li><p>Kommunikationspartner.</p></li>
    <li><p>Kunden.</p></li>
    <li><p>Mitglieder.</p></li>
    <li><p>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</p></li>
  </ul>
  <h3>Zwecke der Verarbeitung</h3>
  <ul>
    <li><p>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</p></li>
    <li><p>Büro- und Organisationsverfahren.</p></li>
    <li><p>Content Delivery Network (CDN).</p></li>
    <li><p>Direktmarketing (z.B. per E-Mail oder postalisch).</p></li>
    <li><p>Kontaktanfragen und Kommunikation.</p></li>
    <li><p>Remarketing.</p></li>
    <li>
      <p>Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</p>
    </li>
    <li><p>Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).</p></li>
    <li><p>Vertragliche Leistungen und Service.</p></li>
    <li><p>Verwaltung und Beantwortung von Anfragen.</p></li>
  </ul>
  <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
  <p>
    Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren
    Basis wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu
    den Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und
    Sitzland gelten können.
  </p>
  <ul>
    <li>
      <p>
        <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene Person hat
        ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
        spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
      </p>
    </li>
    <li>
      <p>
        <strong
          >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong
        >
        - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die
        betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die
        auf Anfrage der betroffenen Person erfolgen.
      </p>
    </li>
    <li>
      <p>
        <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
        Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
        Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
        betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
      </p>
    </li>
  </ul>
  <p>
    <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den
    Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum
    Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch
    personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
    enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
    Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
    Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung
    im Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke
    des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung,
    Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
    Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
    gelangen.
  </p>
  <ul class="m-elements"></ul>
  <h2 id="m27">Sicherheitsmaßnahmen</h2>
  <p>
    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
    Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der
    Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
    Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
    organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
  </p>
  <p>
    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
    Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
    als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
    Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
    Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der
    Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei
    der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
    Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
  </p>
  <p>
    <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten
    Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
    Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
  </p>
  <h2 id="m25">Übermittlung und Offenbarung von personenbezogenen Daten</h2>
  <p>
    Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an
    andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen
    übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können
    z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit IT-Aufgaben beauftragte
    Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden,
    gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere
    entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
    Empfängern Ihrer Daten ab.
  </p>
  <h2 id="m24">Datenverarbeitung in Drittländern</h2>
  <p>
    Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des
    Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
    Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere
    Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
    gesetzlichen Vorgaben.
  </p>
  <p>
    Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher
    Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten
    Datenschutzniveau, zu denen die unter dem "Privacy-Shield" zertifizierten US-Verarbeiter
    gehören, oder auf Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung durch
    sogenannte Standardschutzklauseln der EU-Kommission, des Vorliegens von Zertifizierungen oder
    verbindlicher interner Datenschutzvorschriften, verarbeiten (Art. 44 bis 49 DSGVO,
    Informationsseite der EU-Kommission:
    <a
      href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
      target="_blank"
      referrerpolicy="no-referrer"
      rel="noreferrer"
      >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a
    >
    ).
  </p>
  <h2 id="m354">Wahrnehmung von Aufgaben nach Satzung oder Geschäftsordnung</h2>
  <p>
    Wir verarbeiten die Daten unserer Mitglieder, Unterstützer, Interessenten, Geschäftspartner oder
    sonstiger Personen (Zusammenfassend "Betroffene"), wenn wir mit ihnen in einem Mitgliedschafts-
    oder sonstigem geschäftlichen Verhältnis stehen und unsere Aufgaben wahrnehmen sowie Empfänger
    von Leistungen und Zuwendungen sind. Im Übrigen verarbeiten wir die Daten Betroffener auf
    Grundlage unserer berechtigten Interessen, z.B. wenn es sich um administrative Aufgaben oder
    Öffentlichkeitsarbeit handelt.
  </p>
  <p>
    Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit
    ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Mitgliedschafts- oder
    Vertragsverhältnis, aus dem sich auch die Erforderlichkeit etwaiger Datenangaben ergeben (im
    Übrigen weisen wir auf erforderliche Daten hin).
  </p>
  <p>
    Wir löschen Daten, die zur Erbringung unserer satzungs- und geschäftsmäßigen Zwecke nicht mehr
    erforderlich sind. Dies bestimmt sich entsprechend der jeweiligen Aufgaben und vertraglichen
    Beziehungen. Wir bewahren die Daten so lange auf, wie sie zur Geschäftsabwicklung, als auch im
    Hinblick auf etwaige Gewährleistungs- oder Haftungspflichten auf Grundlage unserer berechtigten
    Interesse an deren Regelung relevant sein können. Die Erforderlichkeit der Aufbewahrung der
    Daten wird regelmäßig überprüft; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen),
        Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten (z.B.
        E-Mail, Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten), Mitglieder, Geschäfts- und Vertragspartner.
      </p>
    </li>
    <li>
      <p>
        <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service,
        Kontaktanfragen und Kommunikation, Verwaltung und Beantwortung von Anfragen.
      </p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
        Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </p>
    </li>
  </ul>
  <h2 id="m182">Kontaktaufnahme</h2>
  <p>
    Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale
    Medien) werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur Beantwortung
    der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
  </p>
  <p>
    Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen
    Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von
    (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der
    Beantwortung der Anfragen.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
        (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
      </p>
    </li>
    <li>
      <p><strong>Betroffene Personen:</strong> Kommunikationspartner.</p>
    </li>
    <li>
      <p><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.</p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
        Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </p>
    </li>
  </ul>
  <h2 id="m391">Kommunikation via Messenger</h2>
  <p>
    Wir setzen zu Zwecken der Kommunikation Messenger-Dienste ein und bitten daher darum, die
    nachfolgenden Hinweise zur Funktionsfähigkeit der Messenger, zur Verschlüsselung, zur Nutzung
    der Metadaten der Kommunikation und zu Ihren Widerspruchsmöglichkeiten zu beachten.
  </p>
  <p>
    Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder E-Mail, kontaktieren. Bitte
    nutzen Sie die Ihnen mitgeteilten Kontaktmöglichkeiten oder die innerhalb unseres
    Onlineangebotes angegebenen Kontaktmöglichkeiten.
  </p>
  <p>
    Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt Ihrer Nachricht und
    Anhänge) weisen wir darauf hin, dass die Kommunikationsinhalte (d.h., der Inhalt der Nachricht
    und angehängte Bilder) von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der
    Nachrichten nicht einsehbar ist, nicht einmal durch die Messenger-Anbieter selbst. Sie sollten
    immer eine aktuelle Version der Messenger mit aktivierter Verschlüsselung nutzen, damit die
    Verschlüsselung der Nachrichteninhalte sichergestellt ist.
  </p>
  <p>
    Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass die Anbieter der
    Messenger zwar nicht den Inhalt einsehen, aber in Erfahrung bringen können, dass und wann
    Kommunikationspartner mit uns kommunizieren sowie technische Informationen zum verwendeten Gerät
    der Kommunikationspartner und je nach Einstellungen ihres Gerätes auch Standortinformationen
    (sogenannte Metadaten) verarbeitet werden.
  </p>
  <p>
    <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir Kommunikationspartner vor der
    Kommunikation mit ihnen via Messenger um eine Erlaubnis bitten, ist die Rechtsgrundlage unserer
    Verarbeitung ihrer Daten deren Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung
    bitten und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir Messenger im Verhältnis
    zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine vertragliche Maßnahme
    und im Fall anderer Interessenten und Kommunikationspartner auf Grundlage unserer berechtigten
    Interessen an einer schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
    Kommunikationspartner an der Kommunikation via Messengern. Ferner weisen wir Sie darauf hin,
    dass wir die uns mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an die
    Messenger übermitteln.
  </p>
  <p>
    <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können jederzeit eine erteilte
    Einwilligung widerrufen und der Kommunikation mit uns via Messenger jederzeit widersprechen. Im
    Fall der Kommunikation via Messenger löschen wir die Nachrichten entsprechend unseren generellen
    Löschrichtlinien (d.h. z.B., wie oben beschrieben, nach Ende vertraglicher Beziehungen, im
    Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen können, etwaige
    Auskünfte der Kommunikationspartner beantwortet zu haben, wenn kein Rückbezug auf eine
    vorhergehende Konversation zu erwarten ist und der Löschung keine gesetzlichen
    Aufbewahrungspflichten entgegenstehen.
  </p>
  <p>
    <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong> Zum Abschluss möchten
    wir darauf hinweisen, dass wir uns aus Gründen Ihrer Sicherheit vorbehalten, Anfragen über
    Messenger nicht zu beantworten. Das ist der Fall, wenn z.B. Vertragsinterna besonderer
    Geheimhaltung bedürfen oder eine Antwort über den Messenger den formellen Ansprüchen nicht
    genügt. In solchen Fällen verweisen wir Sie auf adäquatere Kommunikationswege.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern),
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Inhaltsdaten (z.B.
        Texteingaben, Fotografien, Videos).
      </p>
    </li>
    <li>
      <p><strong>Betroffene Personen:</strong> Kommunikationspartner.</p>
    </li>
    <li>
      <p>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Direktmarketing
        (z.B. per E-Mail oder postalisch).
      </p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </p>
    </li>
  </ul>
  <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Facebook-Messenger:</strong> Facebook-Messenger mit Ende-zu-Ende-Verschlüsselung
        (die Ende-zu-Ende-Verschlüsselung des Facebook-Messengers setzt eine Aktivierung voraus,
        sofern sie nicht standardmäßig aktiviert sein sollte); Dienstanbieter:
        <a
          href="https://www.facebook.com"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com</a
        >, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
        Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:
        <a
          href="https://www.facebook.com"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com</a
        >; Datenschutzerklärung:
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/about/privacy</a
        >; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a
        >; Widerspruchsmöglichkeit (Opt-Out):
        <a
          href="https://www.facebook.com/settings?tab=ads"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/settings?tab=ads</a
        >.
      </p>
    </li>
    <li>
      <p>
        <strong>Slack:</strong> Slack Messenger ohne Ende-zu-Ende-Verschlüsselung; Dienstanbieter:
        Slack Technologies, Inc., 500 Howard Street, San Francisco, CA 94105, USA; Website:
        <a
          href="https://slack.com/intl/de-de/"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://slack.com/intl/de-de/</a
        >; Datenschutzerklärung:
        <a
          href="https://slack.com/intl/de-de/legal"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://slack.com/intl/de-de/legal</a
        >; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnMBAA0&amp;status=Active"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.privacyshield.gov/participant?id=a2zt0000000GnMBAA0&amp;status=Active</a
        >.
      </p>
    </li>
  </ul>
  <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
  <p>
    Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen
    von einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen
    verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
    Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
    Datenbankdienste sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch
    nehmen.
  </p>
  <p>
    Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die
    Nutzer unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der
    Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die
    Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
    Onlineangebotes oder von Webseiten getätigten Eingaben.
  </p>
  <p>
    <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch genommenen
    Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung von
    E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere
    Informationen betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der
    jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung
    von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet
    grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem
    Transportweg verschlüsselt, aber (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren
    eingesetzt wird) nicht auf den Servern, von denen sie abgesendet und empfangen werden. Wir
    können daher für den Übertragungsweg der E-Mails zwischen dem Absender und dem Empfang auf
    unserem Server keine Verantwortung übernehmen.
  </p>
  <p>
    <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser
    Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles).
    Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum
    und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
    nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
    Regelfall IP-Adressen und der anfragende Provider gehören.
  </p>
  <p>
    Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine
    Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
    sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität
    sicherzustellen.
  </p>
  <p>
    <strong>Content-Delivery-Network</strong>: Wir setzen ein "Content-Delivery-Network" (CDN) ein.
    Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
    Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional verteilter und über das
    Internet verbundener Server schneller und sicherer ausgeliefert werden können.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Texteingaben, Fotografien,
        Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </p>
    </li>
    <li>
      <p><strong>Zwecke der Verarbeitung:</strong> Content Delivery Network (CDN).</p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </p>
    </li>
  </ul>
  <h2 id="m29">Cloud-Dienste</h2>
  <p>
    Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter ausgeführte
    Softwaredienste (sogenannte "Cloud-Dienste", auch bezeichnet als "Software as a Service") für
    die folgenden Zwecke: Dokumentenspeicherung und Verwaltung, Kalenderverwaltung, E-Mail-Versand,
    Tabellenkalkulationen und Präsentationen, Austausch von Dokumenten, Inhalten und Informationen
    mit bestimmten Empfängern oder Veröffentlichung von Webseiten, Formularen oder sonstigen
    Inhalten und Informationen sowie Chats und Teilnahme an Audio- und Videokonferenzen.
  </p>
  <p>
    In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter
    gespeichert werden, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder von
    uns sonst, wie im Rahmen dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen
    Daten können insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
    sonstigen Prozessen und deren Inhalte gehören. Die Anbieter der Cloud-Dienste verarbeiten ferner
    Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken und zur Serviceoptimierung
    verwendet werden.
  </p>
  <p>
    Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche Webseiten
    Formulare o.a. Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten
    der Nutzer für Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der
    Mediensteuerung) zu merken, speichern.
  </p>
  <p>
    <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir um eine Einwilligung in den Einsatz
    der Cloud-Dienste bitten, ist die Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann
    deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der
    Cloud-Dienste in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf
    Grundlage unserer berechtigten Interessen (d.h., Interesse an effizienten und sicheren
    Verwaltungs- und Kollaborationsprozessen) verarbeitet
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
        (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B. Angestellte, Bewerber,
        ehemalige Mitarbeiter), Interessenten, Kommunikationspartner.
      </p>
    </li>
    <li>
      <p><strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.</p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </p>
    </li>
  </ul>
  <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Google Cloud-Dienste:</strong> Cloud-Speicher-Dienste; Dienstanbieter: Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google
        LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
        <a
          href="https://cloud.google.com/"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://cloud.google.com/</a
        >; Datenschutzerklärung:
        <a
          href="https://www.google.com/policies/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.google.com/policies/privacy</a
        >, Sicherheitshinweise:
        <a
          href="https://cloud.google.com/security/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://cloud.google.com/security/privacy</a
        >; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive</a
        >; Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung im
        Drittland):
        <a
          href="https://cloud.google.com/terms/data-processing-terms"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://cloud.google.com/terms/data-processing-terms</a
        >; Zusätzliche Hinweise zum Datenschutz:
        <a
          href="https://cloud.google.com/terms/data-processing-terms"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://cloud.google.com/terms/data-processing-terms</a
        >.
      </p>
    </li>
  </ul>
  <h2 id="m136">Präsenzen in sozialen Netzwerken</h2>
  <p>
    Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke, um mit den dort aktiven Nutzern zu
    kommunizieren oder um dort Informationen über uns anzubieten.
  </p>
  <p>
    Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union
    verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B.
    die Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter, die
    unter dem Privacy-Shield zertifiziert sind oder vergleichbare Garantien eines sicheren
    Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich damit verpflichten, die
    Datenschutzstandards der EU einzuhalten.
  </p>
  <p>
    Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für
    Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und
    sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Die
    Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb
    der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
    Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
    Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den
    Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
    (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
    eingeloggt sind).
  </p>
  <p>
    Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
    Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der
    Betreiber der jeweiligen Netzwerke.
  </p>
  <p>
    Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir
    darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die
    Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende
    Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie
    sich an uns wenden.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
        (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </p>
    </li>
    <li>
      <p>
        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Tracking (z.B.
        interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing,
        Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
      </p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </p>
    </li>
  </ul>
  <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601 Willow
        Road, Menlo Park, CA, 94025, USA; Website:
        <a
          href="https://www.instagram.com"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.instagram.com</a
        >; Datenschutzerklärung:
        <a
          href="http://instagram.com/about/legal/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >http://instagram.com/about/legal/privacy</a
        >.
      </p>
    </li>
    <li>
      <p>
        <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4 Grand
        Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker
        Way, Menlo Park, CA 94025, USA; Website:
        <a
          href="https://www.facebook.com"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com</a
        >; Datenschutzerklärung:
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/about/privacy</a
        >; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a
        >; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen:
        <a
          href="https://www.facebook.com/settings?tab=ads"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/settings?tab=ads</a
        >; Zusätzliche Hinweise zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung
        personenbezogener Daten auf Facebook-Seiten:
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/legal/terms/page_controller_addendum</a
        >, Datenschutzhinweise für Facebook-Seiten:
        <a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.facebook.com/legal/terms/information_about_page_insights_data</a
        >.
      </p>
    </li>
  </ul>
  <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
  <p>
    Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer
    jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es
    sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln
    (nachfolgend einheitlich bezeichnet als "Inhalte”).
  </p>
  <p>
    Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der
    Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden
    könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen
    erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
    IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner
    sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische
    oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der
    Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
    Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
    anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten,
    zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit
    solchen Informationen aus anderen Quellen verbunden werden.
  </p>
  <p>
    <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
    den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
    Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
    Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen
    Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
    Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse
        an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
        IP-Adressen).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
      </p>
    </li>
    <li>
      <p>
        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
        Nutzerfreundlichkeit, Vertragliche Leistungen und Service.
      </p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
        DSGVO).
      </p>
    </li>
  </ul>
  <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Google Fonts:</strong> Wir binden die Schriftarten (\"Google Fonts\") des Anbieters
        Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im
        Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer
        berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung
        von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher
        lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
        <a
          href="https://fonts.google.com/"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://fonts.google.com/</a
        >; Datenschutzerklärung:
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://policies.google.com/privacy</a
        >; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active</a
        >.
      </p>
    </li>
  </ul>
  <h2 id="m723">Planung, Organisation und Hilfswerkzeuge</h2>
  <p>
    Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter (nachfolgend bezeichnet
    als "Drittanbieter”) zu Zwecken der Organisation, Verwaltung, Planung sowie Erbringung unserer
    Leistungen ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten wir die
    gesetzlichen Vorgaben.
  </p>
  <p>
    In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der
    Drittanbieter gespeichert werden. Hiervon können diverse Daten betroffen sein, die wir
    entsprechend dieser Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere
    Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und
    deren Inhalte gehören.
  </p>
  <p>
    Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen Beziehungen mit uns auf
    die Drittanbieter bzw. deren Software oder Plattformen verwiesen werden, können die
    Drittanbieter Nutzungsdaten und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu
    Marketingzwecken verarbeiten. Wir bitten daher darum, die Datenschutzhinweise der jeweiligen
    Drittanbieter zu beachten.
  </p>
  <p>
    <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in
    den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
    Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen Leistungen
    sein, sofern der Einsatz der Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden
    die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
    effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
    Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser
    Datenschutzerklärung hinweisen.
  </p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
        (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
      </p>
    </li>
    <li>
      <p>
        <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B. Webseitenbesucher,
        Nutzer von Onlinediensten).
      </p>
    </li>
    <li>
      <p><strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren.</p>
    </li>
    <li>
      <p>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
      </p>
    </li>
  </ul>
  <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
  <ul class="m-elements">
    <li>
      <p>
        <strong>Doodle:</strong> Online Terminplanung; Dienstanbieter: Doodle AG, Werdstrasse 21,
        Postfach, 8021 Zürich, Schweiz; Website:
        <a
          href="https://doodle.com/de"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://doodle.com/de</a
        >; Datenschutzerklärung:
        <a
          href="https://doodle.com/de/datenschutzrichtlinie"
          target="_blank"
          referrerpolicy="no-referrer"
          rel="noreferrer"
          >https://doodle.com/de/datenschutzrichtlinie</a
        >.
      </p>
    </li>
  </ul>
  <h2 id="m12">Löschung von Daten</h2>
  <p>
    Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald
    deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse
    entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den
    Zweck nicht erforderlich sind).
  </p>
  <p>
    Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
    erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
    gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
    steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
    natürlichen oder juristischen Person erforderlich ist.
  </p>
  <p>
    Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der
    einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
  </p>
  <ul class="m-elements"></ul>
  <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
  <p>
    Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir
    passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen
    eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
    Benachrichtigung erforderlich wird.
  </p>
  <h2 id="m42">Begriffsdefinitionen</h2>
  <p>
    In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung
    verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im
    Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
    Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch
    sortiert.
  </p>
  <ul class="glossary">
    <li>
      <strong>Content Delivery Network (CDN):</strong> Ein "Content Delivery Network" (CDN) ist ein
      Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große Mediendateien, wie
      Grafiken oder Programm-Skripte mit Hilfe regional verteilter und über das Internet verbundener
      Server, schneller und sicherer ausgeliefert werden können.
    </li>
    <li>
      <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die
      sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene
      Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder
      indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
      Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder
      mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen,
      physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
      Identität dieser natürlichen Person sind.
    </li>
    <li>
      <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics
      bezeichnet) dient der Auswertung der Besucherströme eines Onlineangebotes und kann das
      Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B. Inhalten von
      Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen,
      zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich interessieren.
      Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher
      anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
      eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines
      Onlineangebotes zu erhalten.
    </li>
    <li>
      <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“ spricht man, wenn z.B. zu
      Werbezwecken vermerkt wird, für welche Produkte sich ein Nutzer auf einer Webseite
      interessiert hat, um den Nutzer auf anderen Webseiten an diese Produkte, z.B. in
      Werbeanzeigen, zu erinnern.
    </li>
    <li>
      <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten von Nutzern über
      mehrere Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im Hinblick auf
      die genutzten Onlineangebote Verhaltens- und Interessensinformationen in Cookies oder auf
      Servern der Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling). Diese
      Informationen können anschließend z.B. eingesetzt werden, um den Nutzern Werbeanzeigen
      anzuzeigen, die voraussichtlich deren Interessen entsprechen.
    </li>
    <li>
      <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische
      Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über
      die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
    </li>
    <li>
      <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
      Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
      personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten,
      sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
    </li>
  </ul>
  <p></p>
  <p class="seal">
    <a
      href="https://datenschutz-generator.de/?l=de"
      title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
      target="_blank"
      referrerpolicy="no-referrer"
      rel="noreferrer"
      >Erstellt mit Datenschutz-Generator.de von Dr. jur. Thomas Schwenke</a
    >
  </p>
</div>
