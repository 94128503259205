import { Component, Inject } from '@angular/core';

import { KontaktModalComponent } from '../kontakt-modal/kontakt-modal.component';
import { KontaktSheetComponent } from '../kontakt-sheet/kontakt-sheet.component';
import { DOCUMENT } from '@angular/common';
import { NgIconComponent } from '@ng-icons/core';
import { ActionSheetComponent, DialogComponent } from 'ngx-concern';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss'],
  standalone: true,
  imports: [
    ActionSheetComponent,
    DialogComponent,
    NgIconComponent,
    KontaktModalComponent,
    KontaktSheetComponent,
  ],
})
export class KontaktComponent {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public mailTo() {
    const hidden = 'Wm5NdGFXNW1iMEIxYm1rdGEyOWxiRzR1WkdVPQ==';
    const mail = atob(atob(hidden));
    console.log(mail);
    const a = this.document.createElement('a');
    a.target = '_blank';
    a.referrerPolicy = 'no-referrer';
    a.rel = 'noreferrer';
    a.href = `mailto:${mail}`;
    a.click();
  }
}
