import { Component } from '@angular/core';

@Component({
  selector: 'app-fs-logo',
  standalone: true,
  imports: [],
  templateUrl: './fs-logo.component.svg',
  styleUrl: './fs-logo.component.scss',
})
export class FsLogoComponent {}
