<app-toolbar color="primary" #toolbar [class.hidden]="toolbarHidden$ | async">
  <a id="badge" href="#hero" title="Start" class="desktop">
    <img
      src="assets/fslogo_vector.png"
      class="logo"
      height="32"
      width="32"
      alt="Logo der Fachschaft"
    />
  </a>

  <div id="links" class="desktop">
    <a [class.is-in-view]="studium$ | async" href="#studium" title="Studium">Studium</a>
    <a [class.is-in-view]="events$ | async" href="#events" title="Events">Events</a>
    <a [class.is-in-view]="mitglieder$ | async" href="#mitglieder" title="Mitglieder">Mitglieder</a>
    <a href="#kontakt" title="Kontakt">Kontakt</a>
  </div>

  <div id="links" class="mobile">
    <a href="#hero" title="Start">
      <app-fs-logo />
    </a>
    <a [class.is-in-view]="studium$ | async" href="#studium" title="Studium">
      <ng-icon name="matLocalLibrary"></ng-icon>
    </a>
    <a [class.is-in-view]="events$ | async" href="#events" title="Events">
      <ng-icon name="matEvent"></ng-icon>
    </a>
    <a [class.is-in-view]="mitglieder$ | async" href="#mitglieder" title="Mitglieder">
      <ng-icon name="matGroup"></ng-icon>
    </a>
    <a href="#kontakt" title="Kontakt">
      <ng-icon name="matContactMail"></ng-icon>
    </a>
  </div>

  <div id="scrolling-indicator" [style.width.%]="scrollPercent$ | async" #scrollIndicator></div>
</app-toolbar>

<div
  id="hero"
  class="section has-background"
  #hero
  appIsVisible
  (isVisible)="isVisible($event, 'hero')"
>
  <h1 class="screen-reader">Start</h1>
  <app-hero [studiumElement]="studium"></app-hero>
  <img
    [class.visible]="hero$ | async"
    [lazyLoad]="heroImg"
    alt="Hintergrundbild mit Uniwiese"
    aria-hidden="true"
    style="filter: blur(4px)"
  />
</div>

<div
  id="studium"
  class="section padded has-background white-gradient"
  #studium
  appIsVisible
  (isVisible)="isVisible($event, 'studium')"
>
  <h1 class="screen-reader">Studium</h1>
  <app-studium></app-studium>
  <img
    [lazyLoad]="studiumImg"
    alt="Hintergrundbild vom Philosophikum der Universität zu Köln - stilisiert"
    aria-hidden="true"
  />
</div>

<div
  id="events"
  class="section padded has-background white-gradient"
  #events
  appIsVisible
  (isVisible)="isVisible($event, 'events')"
>
  <h1 class="screen-reader">Events</h1>
  <app-events></app-events>
  <img
    [lazyLoad]="eventsImg"
    alt="Hintergrundbild vom Hörsaalgebäude der Universität zu Köln - stilisiert"
    aria-hidden="true"
  />
</div>

<div
  id="mitglieder"
  class="section"
  #mitglieder
  appIsVisible
  (isVisible)="isVisible($event, 'mitglieder')"
>
  <h1 class="screen-reader">Mitglieder</h1>
  <app-mitglieder></app-mitglieder>
</div>

<div
  id="kontakt"
  class="section has-background white-gradient"
  #kontakt
  appIsVisible
  (isVisible)="isVisible($event, 'kontakt')"
>
  <h1 class="screen-reader">Kontakt</h1>
  <app-kontakt></app-kontakt>
  <img
    [lazyLoad]="kontaktImg"
    alt="Hintergrundbild vom Hauptgebäude der Universität zu Köln - stilisiert"
    aria-hidden="true"
  />
</div>
